<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container id="iiotDiskContainer" class="mt-5 disk-margin">
    <v-form id="iiotDiskForm" ref="diskForm" data-cy="iiotDiskForm">
      <v-row v-for="(i, index) in prop" :key="index">
        <v-col cols="5" xs="4" class="pa-0">
          <v-row class="ma-0">
            <v-text-field
              :id="`iiotDiskNameInput_${index}`"
              v-model="i.data_disk"
              :data-cy="`iiotDiskNameInput_${index}`"
              :label="$t('workloadVersion.dataDisk.dataDiskName')"
              validate-on-blur
              :rules="[rules.required, rules.patternDiskName, rules.duplicateDisk]"
              :disabled="isReleased"
              @keydown.enter.prevent="triggerSubmit"
            />
          </v-row>
        </v-col>
        <v-row justify="center" class="pt-9 pl-4">
          <span>:</span>
        </v-row>
        <v-col class="pa-0 pl-4" cols="3">
          <v-text-field
            :id="`iiotDiskSize_${index}`"
            ref="diskSize"
            v-model.number="i.disk_size"
            :data-cy="`iiotDiskSize_${index}`"
            :label="$t('workloadVersion.dataDisk.diskSize')"
            validate-on-blur
            :rules="[rules.required, rules.patternDiskSize, rules.minAndMax(i)]"
            :disabled="isReleased"
            @keydown.enter.prevent="triggerSubmit"
          />
        </v-col>
        <v-col :id="`iiotDiskSelect_${index}`" cols="3" xs="4" class="pa-0 pr-3 pl-3">
          <v-select
            :id="`iiotDiskUnit_${index}`"
            v-model="i.disk_unit"
            :data-cy="`iiotDiskUnit_${index}`"
            :items="measure"
            :disabled="isReleased"
            attach
            @change="onSelectedMeasureChange"
          />
        </v-col>
        <v-col cols="1" class="mt-4 pl-3 pa-0">
          <v-btn
            :id="`iiotDiskRemoveButton_${index}`"
            fab
            x-small
            dark
            class="primary-tttech-background-btn"
            :disabled="isReleased"
            @click="removeDisk(index)"
          >
            <v-icon dark> mdi-minus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="isFormValid">
        <div class="mb-1 ml-1">
          <v-btn
            id="iiotDiskNewButton"
            data-cy="iiotDiskNewButton"
            fab
            x-small
            dark
            class="primary-tttech-background-btn"
            :disabled="isReleased"
            @click="addNewDisk()"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </div>
        <span class="mt-1 ml-3">
          {{ $t('workloadVersion.dataDisk.newDataDisk') }}
        </span>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { MEASURES_DISK, VALIDATION_REGEX } from '@/constants';

export default {
  props: {
    prop: {
      type: Array,
    },
    version: {
      type: Object,
    },
    isReleased: {
      type: Boolean,
    },
  },
  data: () => ({
    VALIDATION_REGEX,
    MEASURES_DISK,
    isFormValid: false,
    measure: ['KB', 'MB', 'GB', 'TB'],
  }),
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('workloadVersion.required'),
        patternDiskName: (value) =>
          VALIDATION_REGEX.LETTER_NUMBER_AND_UNDERSCORE.test(value) ||
          this.$t('workloadVersion.dataDisk.patternDiskName'),
        patternDiskSize: (value) =>
          VALIDATION_REGEX.DATA_DISK_SIZE.test(value) || this.$t('workloadVersion.dataDisk.patternDiskSize'),
        minAndMax: (value) => {
          const { disk_size, disk_unit } = value;
          if (disk_size && disk_unit) {
            const range = MEASURES_DISK.find((element) => element.measure === disk_unit);
            if (disk_size > range.max) {
              return (
                false ||
                this.$t('workloadManagement.memoryMaxValueMessage', {
                  value: range.max,
                  measure: disk_unit,
                })
              );
            }
            if (disk_size < range.min) {
              return (
                false ||
                this.$t('workloadManagement.memoryMinValueMessage', {
                  value: range.min,
                  measure: disk_unit,
                })
              );
            }
          }
          return true;
        },
        duplicateDisk: (value) => {
          const found = this.prop.filter((element) => element.data_disk === value);
          if (found.length > 1) {
            return false || this.$t('workloadVersion.dataDisk.duplicateDataDisk');
          }
          return true;
        },
      };
    },
  },
  mounted() {
    this.isFormValid = this.validationForm();
  },
  updated() {
    this.isFormValid = this.validationForm();
  },
  methods: {
    addNewDisk() {
      this.$refs.diskForm.$el.dispatchEvent(new Event('input'));
      const obj = {
        data_disk: '',
        disk_size: null,
        disk_unit: 'MB',
      };
      this.prop.push(obj);
    },
    async removeDisk(index) {
      await this.prop.splice(index, 1);
      this.$refs.diskForm.$el.dispatchEvent(new Event('input'));
    },
    onSelectedMeasureChange() {
      this.isFormValid = this.validationForm();
      this.$refs.diskForm.$el.dispatchEvent(new Event('input'));
    },
    validationForm() {
      return this.$refs.diskForm ? this.$refs.diskForm.validate() : false;
    },
    triggerSubmit() {
      if (this.isFormValid) {
        this.$emit('submit-event');
      }
    },
  },
};
</script>
<style>
.disk-margin {
  margin-bottom: 140px;
}
</style>

<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container v-if="version && version.workloadProperties" id="iiotNetworkingContainer" class="mt-8 ml-4">
    <v-form id="iiotNetworkingForm" ref="networking">
      <v-row>
        <v-col
          v-resize="onResize"
          cols="12"
          lg="4"
          class="pl-0 pt-0 pr-0"
          :class="{ 'pr-10': type === 'docker' && isMarginVisible, 'pr-5': !isMarginVisible }"
        >
          <span v-if="type === 'docker'"> {{ $t('workloadVersion.dockerNetworks') }}</span>
          <br v-if="type === 'docker'" />
          <span v-if="type === 'vm'"> {{ $t('workloadVersion.networkInterfaces') }}</span> <br />
          <div v-if="type === 'docker'">
            <docker-network
              ref="dockerNetwork"
              :prop="version.workloadProperties.networks"
              :version="version"
              :is-released="isReleased"
            />
          </div>
          <div v-if="type === 'vm'" class="mt-5">
            <network-interface
              ref="networkInterface"
              :prop="version.workloadProperties.libvirt_networks"
              :version="version"
              :is-released="isReleased"
              @submit-event="triggerSubmit"
            />
          </div>
        </v-col>
        <div v-if="isMarginVisible && type === 'docker'">
          <v-divider vertical />
        </div>
        <v-col cols="12" lg="4" class="pl-0 pt-0 pr-0" :class="{ 'pl-5': isMarginVisible, 'pr-5': !isMarginVisible }">
          <span v-if="type === 'docker'" :class="{ 'pl-11': isMarginVisible }">
            {{ $t('workloadVersion.portsTitle').toUpperCase() }}
          </span>
          <br />
          <ports
            v-if="type === 'docker' && version.workloadProperties"
            ref="portsForm"
            :prop="version.workloadProperties.port_mappings_protocol"
            :version="version"
            :is-released="isReleased"
            :class="{ 'pl-15': isMarginVisible }"
            class="mb-12"
            @submit-event="triggerSubmit"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Ports from '@/components/workloads/dockerVmComponents/ports/Ports.vue';
import NetworkInterface from '@/components/workloads/dockerVmComponents/networkInterface/NetworkInterface.vue';
import DockerNetwork from '@/components/workloads/dockerVmComponents/dockerNetwork/DockerNetwork.vue';

export default {
  components: { Ports, NetworkInterface, DockerNetwork },
  props: {
    version: {
      type: Object,
    },
    type: {
      type: String,
    },
    isReleased: {
      type: Boolean,
    },
  },
  data: () => ({
    isMarginVisible: false,
  }),
  methods: {
    triggerSubmit() {
      this.$emit('submit-event');
    },
    onResize() {
      this.isMarginVisible = window.innerWidth > 1264;
    },
  },
};
</script>
